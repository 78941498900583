// 定义 筛选项 参数 类型
type FilterDataType = {
  beginEpochMillis?: number | string; // 开始时间戳
  endEpochMillis?: number | string;  // 结束时间戳
  id?: number | string; // 事件id
  infoType: number | string; // 信息类型
  page: number; // 页码
  pageSize: number; // 每页条数
  platIds: Array<string | number | null | undefined>; // 输入框关键词
  wd?: string; // 输入框关键词
  wdType?: number | string; // 查询方式 0用户id 1用户名 2关键词
  imgId?: string; // 图片ID
  imgType?: number; // 图片搜索类型 0 1
}

// 响应值内容类型
type ResponseType = {
  code: string;
  msg: string;
  data: any;
}

// 请求响应类型
type RequestDataType = {
  config: object,
  data: ResponseType;
  headers: object;
  request: any;
  status: number;
  statusText: string;
}

//
interface listResponse {
  data: Array<any>; // 信息列表
  page: number; // 当前页
  size: number; // 每页展示的条数
  totalNum: number; // 总记录数
  totalPage: number; // 总页数
}


/*
  appId: ["CLOUD_VIEW"]
  bid: "4616233114833784833"
  collection: false
  commentsCount: null
  coverId: "4616233679118667777"
  createdTime: "2022-04-28 11:01:23.+0800"
  dataProvider: ["kd"]
  esPropInfo: {docId: "4616233114833784833", index: "cloud-view-video-0", version: null}
  extractedContent: null
  forwardsCount: null
  frameImageIds: ["4616233679118667777", "4616233661636808705", "4616233665176801281", "4616233682885152769",…]
  imageTopicIds: null
  imageTopics: null
  likesCount: 710
  nickname: " 深圳卫视 "
  publishTime: "2022-03-25 17:00:20"
  sourceId: "www.kuaishou.com"
  sourceName: "快手"
  targetBid: null
  title: "来自梅山小学的教师们，白衣为甲，无畏逆行，化身“大白”奔赴深圳疫情最严重的上沙东村。这一段不平凡的时光，成为他们这辈子不一样的“光荣烙印”！"
  typeId: ["VIDEO"]
  url: "https://www.kuaishou.com/short-video/3x6j2bciucqrpvi?authorId=3xqqcujmyyw2qwu&streamSource=profile&area=profilexxnull"
  userId: "3xqqcujmyyw2qwu"
*/

// 初始化 获取列表 筛选项 分页
export function initGeListByFilter(this: any): void {
  // console.log(19,field,this);
  // this.filedData.beginEpochMillis = '';
  // this.filedData.endEpochMillis = '';
  // this.filedData.infoType = 20;
  this.status = 'loading';
  this.data.list = [];
  // console.log(77,this.filedData);

  let url: string = `${this.$store.state.common.uviewServer}data/s/about-event`; // data/s/page-info  data/s/about-event
  this.axios.post(url, this.filedData).then((res: RequestDataType) => {
    // console.log(21,res);
    if (res.data.code.substr(0, 1) === 'R') {
      let result: listResponse = res.data.data;
      this.data.totalNum = result.totalNum; // 总记录数
      this.data.list = result.data; // 信息列表
    }
    this.status = 'nodata';
  });
}
