







































import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";
import EventList from "./components/event-list/index.vue";
import ContainerHeaderTitle from "../components/container-header-title/index.vue";
import ContainerFilter from "../components/container-filter/index.vue";
import ContainerList from "../components/container-list/index.vue";
import PageStatus from "@/components/page-status/index.vue";
import { initGeListByFilter } from "./script";

interface Vdata {
  name: string;
  eventId: number | string;
  totalNum: number;
  list: Array<any>;
}

interface adCodeVosItem {
  adCode: string;
  type: number;
}

// 定义 筛选项 参数 类型
type FilterDataType = {
  beginEpochMillis?: number | string | null; // 开始时间戳
  endEpochMillis?: number | string | null; // 结束时间戳
  id?: number | string; // 事件id
  infoType: number | string; // 信息类型
  page: number; // 页码
  pageSize: number; // 每页条数
  platIds: Array<string | number | null | undefined>; // 输入框关键词
  wd?: string; // 输入框关键词
  wdType?: number | string; // 查询方式 0用户id 1用户名 2关键词
  imgId?: string; // 图片ID
  imgType?: number; // 图片搜索类型 0 1
  adCodeVos?: Array<adCodeVosItem>;
};

// 列表页传递过来的
interface FieldPageAttr {
  page: number;
}

@Component({
  components: {
    EventList,
    ContainerHeaderTitle,
    ContainerFilter,
    ContainerList,
    PageStatus,
  },
})
export default class EventPush extends Vue {
  // data
  private data: Vdata = {
    name: "event-push", // 事件推送页面
    eventId: 1, // 事件ID
    totalNum: 0, // 总记录数
    list: [], // 列表
  };
  // REQUEST FILTER DATA
  private filedData: FilterDataType = {
    beginEpochMillis: 0,
    endEpochMillis: 0,
    id: 0,
    infoType: 0,
    page: 1,
    pageSize: 20,
    platIds: [],
    wd: "",
    wdType: 0,
    // 视图搜索 参数
    imgId: "",
    imgType: 0, // 0, 1
    adCodeVos: []
  };
  private status: string = "nodata"; // 列表状态
  private refreshOnoff: boolean = true; // 刷新开关

  // mounted
  private mounted(): void {}

  // methods 获取筛选项参数
  private getField(field: FilterDataType): void {
    this.filedData = { ...this.filedData, ...field };
    // REQUEST
    initGeListByFilter.bind(this)(); // 请求列表
  }

  // methods 翻页
  private getFieldList(params: FieldPageAttr): void {
    this.filedData = { ...this.filedData, ...params };
    // REQUEST
    initGeListByFilter.bind(this)(); // 请求列表
  }

  // methods 点击事件列表 获取事件ID
  private getEventId(id: number | string): void {
    /* this.refreshOnoff = false;
    let t = setTimeout(() => {
      this.data.eventId = id;
      this.refreshOnoff = true;
    }, 80); */
    this.data.eventId = id;
  }
}
