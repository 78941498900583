
















































import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";
//   import { initGetSelectList } from '@/views/container/event-push/components/event-list/script';
import { initGetListBySelectedEvent } from "./script";

interface EventItem {
  id: number | string; // 事件项
  name: string; // 事件名称
}

interface Vdata {
  name: string; // 组件名
  selectOptions: Array<object>; // 下拉列表
  selectValue: string | number; // 下拉值
  eventList: Array<EventItem>; // 事件展示列表
  eventListActiveIndex: number; // 当前激活的事件列表项
}

type Snumber = string | number;

@Component({
  components: {},
})
export default class EventList extends Vue {
  // data
  private data: Vdata = {
    name: "event-list", // 事件推送 事件列表
    selectOptions: [
      { value: 10, label: "关键词" },
      { value: 50, label: "图片" },
    ],
    selectValue: 10,
    eventList: [
      // { id: 1, name: '蔡英文' }, { id: 2, name: '蔡其昌' }, { id: 3, name: '游锡堃' }, { id: 4, name: '赖清德' }
    ], // 事件列表
    eventListActiveIndex: 0, // 当前选中的事件
  };

  // emit
  @Emit("getEventId")
  getEventId(params: Snumber): Snumber {
    return params;
  }

  // methods activeCurrentItem 点击当前
  private activeCurrentItem(it: EventItem, i: number): void {
    console.log(it);
    this.data.eventListActiveIndex = i;
    // REQUEST
    this.getEventId(it.id);
  }

  // methods getSearchValue 获取事件 下拉列表
  private getSearchValue(): void {
    // console.log(this.data.selectValue);
    // REQUEST
    initGetListBySelectedEvent.bind(this)(this.data.selectValue);
  }

  // methods 初始化 获取 下拉列表
  private initGetSelectList(): void {
    // initGetSelectList.bind(this)();
    this.getSearchValue();
  }

  // mounted
  private mounted(): void {
    this.data.eventListActiveIndex = 0; // 默认初始化 0
    // 初始化 REQUEST
    this.initGetSelectList(); // 初始化获取事件列表
  }
}
