
// 初始化 获取 事件下拉列表
// export function initGetSelectList(this:any): void {
//     let url = `${this.$store.state.common.qbServer}xx/xx`;
//     this.axios.get(url).then((res:object) => {
//         console.log(res);
//         // 设置当前选中的事件ID
//         // 获取当前 事件ID下的 列表
//         // initGetListBySelectedEvent.bind(this)(1);
//     })
// }


const result:Array<any> = [
  { eventName: "事件1",id: 1, type: 10 },
  { eventName: "事件2",id: 2, type: 10 },
  { eventName: "事件3",id: 3, type: 10 },
  { eventName: "事件4",id: 4, type: 10 },
  { eventName: "事件5",id: 5, type: 10 }
];

// 初始化 当前事件下的 列表
export function initGetListBySelectedEvent(this:any,type:string|number): void {
    let url = `${this.$store.state.common.uviewServer}send-setting/event/${type}`;
    // console.log(url);
    this.data.eventList = [];

    this.axios.get(url).then((res:any) => {
        // console.log(res);
        if(res.data.code.substr(0,1) === 'R'){
          this.data.eventList = res.data.data;
          if(this.data.eventList.length > 0){
            this.data.eventListActiveIndex = 0;
            this.getEventId(this.data.eventList[0].id);
          }else{
            this.getEventId();
          }
        }
    })
}
